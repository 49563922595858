.intro-card {
  text-align: left;
  padding: 10px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  width: calc(100vw - 40px);
}

@media (min-width: 600px) {
  .intro-card {
    max-width: 600px;
    padding: 20px;
  }
}

.card__title.intro-card__title {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 1rem 0;
}

@media (min-width: 475px) {
  .card__title.intro-card__title {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.intro-card__paragraph {
  margin-top: 0;
}

.intro-card__email-link {
  color: inherit;
  font-style: inherit;
  font-weight: bold;
}

.intro-card__log-in-form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}

@media (min-width: 475px) {
  .intro-card__log-in-form {
    max-width: 60%;
  }
}

.intro-card__log-in-label {
  color: #555;
}

.intro-card__log-in-password {
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.8rem 1rem 0.5rem;
}

.intro-card__log-in-button {
  background: #e7f3ff;
  border: none;
  border-radius: 4px;
  color: #1877f2;
  cursor: pointer;
  font-family: helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 1rem;
  padding: 0.8rem 1rem 0.5rem;
}

.intro-card__log-in-button:hover {
  background: linear-gradient(#0000000d, #0000000d) #e7f3ff;
}

.intro-card__log-in-button[disabled],
.intro-card__log-in-button[disabled]:hover {
  background: #e7e7e7;
  color: #616161;
  cursor: default;
}

.intro-card__log-in-button.intro-card--loading::after {
  animation: log-in-button-loading 3s infinite;
  content: "";
  display: inline-block;
  text-align: left;
  width: 20px;
}

@keyframes log-in-button-loading {
  0% { content: ""; }
  25% { content: "." }
  50% { content: ".." }
  75% { content: "..." }
  100% { content: ""; }
}

.intro-card__log-in-error {
  display: none;
  color: #900;
  font-style: italic;
  margin-top: 1rem;
}

.intro-card__log-in-error.intro-card--visible {
  display: block;
}
